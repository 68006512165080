@media(min-width:1200px) and (max-width:1399px){

}
@media(min-width:992px) and (max-width:1199px){

}
@media(min-width:768px) and (max-width:991.98px){
  
}
@media(min-width:576px) and (max-width:767px){
    .first h2 {
        font-size: 21px;

    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 12px;
    }
    .footerr-main h5 span{
        font-size: 12px;
        display: inline-block;
        
    }
}

@media(width:575px){
    .first h2 {
        font-size: 25px;

    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 12px;
    }
    .footerr-main h5 span{
        font-size: 12px;
        display: inline-block;
        
    }
}
@media only screen and (min-width: 480px) and (max-width: 574px) {
    .first h2 {
        font-size: 25px;

    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 12px;
    }
    .footerr-main h5 span{
        font-size: 12px;
        display: inline-block;
        
    }
    
}
@media only screen and (min-width: 426px) and (max-width: 479px) {
   
    .first h2 {
        font-size: 25px;

    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    #footerr {

        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        text-align: center;
        font-size: 20px;
    }
    .first p br {
        display: none;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 12px;
        line-height: 20px;
    }
    .footerr-main h5 span{
        font-size: 12px;
        display: inline-block;
        
    }
        
}
@media only screen and (min-width: 361px) and (max-width: 425px) {
    .first h2 {
        font-size: 21px;

    }
    .first p br {
        display: none;
        
    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    #footerr {
        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 12px;
        line-height: 20px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 10px;
        line-height: 20px;
    }
    .footerr-main h5 span{
        font-size: 10px;
        display: inline-block;
        
    }
    
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
    .first h2 {
        font-size: 18px;

    }
    .first p {
        font-size: 11px;
    }
    .first span {
        font-size: 11px;
    }
    .btna a {
        padding: 18px 40px;
        font-size: 11px;
    }
    #frist {
        padding-top: 20px;
        padding-bottom: 60px;
    }
    #footerr {
        padding-top: 40px;
    }
    .footerr-main h5 {
        padding-bottom: 25px;
        font-size: 10px;
        line-height: 20px;
        
    }
    .footerr-main h5 span{
        font-size: 10px;
        display: inline-block;
        
    }

    .first p br {
        display: none;
    }
}

