/*
.mainSection .col-md-6 {
display: flex;
align-items: baseline;
justify-content: center;
height: 450px;
}
*/

.firstLine {
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
}

.firstLine img {
    height: 367.883px
}

.second {
    justify-content: center;
}

.second img {
    margin: 0px 15px;
}

.middleImg {
    margin-right: 18px;
}

.three {
    transform: scale(0.9);
   margin-top: -15px;
}

.redBar {
    transform: translateY(20px) translateX(30px) !important;
    width: 420px !important;
}

.subscribe {
    height: 54px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.subscribe input {
    height: 100%;
    border: none;
    outline: none;
flex: 1;
padding: 0px 10px;
}

.subscribe img {
    height: 40px;
    width: 40px;
    background-color: #EC4A70;
    padding: 10px;
    border-radius: 20px;
    transform: translateX(-45px);
    cursor: pointer;
    z-index: 10;
}

.subscribe img:hover {
    box-shadow: 0px 5px 15px #EC4A70;
}

@media only screen and (max-width: 600px) {
    .firstLine {
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 0;
    }
.firstLine img {
    height: 365.433px !important;
    width: 328.8px !important;
}

.second img {
    margin: 0px 0px;
}

.middleImg {
    margin-right: 0;
}

.three {
    transform: scale(0.9);
    margin-bottom: 40px;
    margin-top: 0;
}
    
.redBar {
    transform: translateY(10px) translateX(40px) !important;
    width: 170px !important;
    height: 20px !important;
}

.subscribe {
    width: max-content;
    margin: 0px auto;
}

.subscribe img {
    transform: translateX(-5px);
}

}