/*============= Common CSS goes here ===========*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;

}
ul,ol{
    list-style-type: none;
}


.btn__buy {
    padding: 24px 35px;
    border-radius: 18px;
}.btn__red {
    color: #fff;
    background-color: #ec4a70;
}

.first h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 180%;
    color: #001833;
}
.first p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 240%;
    color: #676767;
    padding-bottom: 10px;
    
}
.first span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    color: #EC4A70;
    
}
.btna a{
    background: #EC4A70;

    border-radius: 16px;
    padding: 20px 66px;
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 220%;
    text-decoration: none;
}
.btna a:hover {
    box-shadow: 0px 20px 60px rgba(236, 74, 112, 0.7);
    color: #fff;
}

#frist {
    padding-top: 100px;
}
#last {
    padding-bottom: 80px;
}
#translations-reviewuson {
    font-weight: 800;
}
.reviewus {
    font-weight: 700;
}
#footerr {
    background: #38326F;
    border-radius: 0px;
    height: 175px;
    padding-top:80px;
}
.footerr-main h5 {
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    
}
.footerr-main h5  span{
    color: #fff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    
}