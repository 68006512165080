.socials {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.socials img {
    background-color: rgba(255, 255, 255, .1);
    margin: 0px 5px;
    padding: 10px;
    height: 48px;
    border-radius: 50%;
}